import React, { useState } from 'react';
import { defineMessages } from 'react-intl';
import {
  CreateUniversalTag,
  isUniversalTag,
  MaybeTrackedValue,
  UniversalTag,
} from '@atossa/core';
import { Api } from '../../Api';
import {
  CJField,
  CJForm,
  TextInput,
  ToggleInput,
  useCJForm,
} from '@cjdev-internal/visual-stack-x/CJForm';
import intl from '../../utils/intl';
import { ActionModal } from '@cjdev-internal/visual-stack-x/ActionModal';
import * as R from 'ramda';
import { ToastOptions } from '@cjdev-internal/visual-stack-x/Toast';
import { Button } from '@cjdev-internal/visual-stack-x/Button';
import { Row } from '@cjdev-internal/visual-stack-x/components/Row';
import { Stack } from '@cjdev-internal/visual-stack-x/components/Stack';
import { appConfig } from 'appConfig';

export interface TagModalProps {
  editingTag?: UniversalTag;
  isCreatingNewTag: boolean;
  onClose: () => void;
  api: Api;
  cid: string;
  showToast: (options: ToastOptions) => void;
  setTags: React.Dispatch<MaybeTrackedValue<UniversalTag>[]>;
}

export const TagModal = (props: TagModalProps) => {
  const config = useCJForm({
    defaultValues: {
      tagName: props.editingTag?.name || '',
      tagUrl: props.editingTag?.url || '',
      consentToggle:
        props.editingTag?.consentConfig?.enableAdvertiserConsentSignal || false,
      loyaltyToggle:
        props.editingTag?.consentConfig?.enableAdvertiserLoyaltyOverride ||
        false,
    },
  });

  const isOpen = props.isCreatingNewTag || !!props.editingTag;
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const onSubmitEditTag = async (t: Required<UniversalTag>) => {
    await editTag(props.setTags, props.api, props.cid, t);
    props.showToast({
      message: intl.formatMessage(content.tagSavedSuccessAlert),
      type: 'success',
      duration: 2000,
    });
    props.onClose();
  };

  const onSubmitCreateTag = async (t: CreateUniversalTag) => {
    await createTag(props.setTags, props.api, props.cid, t);
    props.showToast({
      message: intl.formatMessage(content.tagCreatedSuccessAlert),
      type: 'success',
      duration: 2000,
    });
    props.onClose();
  };
  const type = R.prop('editingTag', props) ? 'edit' : 'create';
  const consentToggleValue = config.watch('consentToggle');

  return (
    <ActionModal
      isOpen={isOpen}
      headerText={
        type === 'edit'
          ? intl.formatMessage(content.editTagModalHeader)
          : intl.formatMessage(content.createTagModalHeader)
      }
      shouldCloseOnOverlayClick={true}
      type={'custom'}
      width="464px"
      body={
        <CJForm
          {...config}
          onSubmit={async (values) => {
            try {
              if (type === 'edit') {
                await onSubmitEditTag({
                  id: props.editingTag?.id!,
                  name: values.tagName!,
                  url: values.tagUrl!,
                  consentConfig: {
                    enableAdvertiserConsentSignal:
                      values.consentToggle! || false,
                    enableAdvertiserLoyaltyOverride:
                      values.loyaltyToggle || false!,
                  },
                });
              } else {
                await onSubmitCreateTag({
                  name: values.tagName,
                  url: values.tagUrl,
                });
              }
            } catch (e) {
              const failedMessage = intl.formatMessage(
                type === 'edit'
                  ? content.tagSavedFailAlert
                  : content.tagCreatedFailAlert
              );
              props.showToast({ message: failedMessage, type: 'warning' });
              props.onClose();
            }
          }}
        >
          <CJField
            name="tagName"
            label={intl.formatMessage(content.tagModalNameLabel)}
          >
            <TextInput name="tagName" autoFocus={true} />
          </CJField>
          <CJField
            name="tagUrl"
            label={intl.formatMessage(content.tagModalUrlLabel)}
          >
            <TextInput name="tagUrl" />
          </CJField>
          {(() => {
            if (type === 'edit' && appConfig.isConsentLoyaltyUiEnabled) {
              return (
                <>
                  <CJField
                    help={(value) => {
                      if (
                        (value === false || value === undefined) &&
                        props.editingTag?.consentConfig
                          ?.enableAdvertiserConsentSignal
                      ) {
                        return intl.formatMessage(
                          content.tagModalDisableWarning
                        );
                      }
                    }}
                    name="consentToggle"
                    label={intl.formatMessage(content.tagModalConsentLabel)}
                  >
                    <ToggleInput
                      name="consentToggle"
                      onChange={(e) => {
                        if (!config.getValues().consentToggle) {
                          config.setValue('loyaltyToggle', false);
                        }
                      }}
                    />
                  </CJField>
                  <CJField
                    help={(value) => {
                      if (
                        (value === false || value === undefined) &&
                        props.editingTag?.consentConfig
                          ?.enableAdvertiserLoyaltyOverride
                      ) {
                        return intl.formatMessage(
                          content.tagModalDisableWarning
                        );
                      }
                    }}
                    name="loyaltyToggle"
                    label={intl.formatMessage(content.tagModalLoyaltyLabel)}
                  >
                    <ToggleInput
                      readOnly={!consentToggleValue}
                      name="loyaltyToggle"
                    />
                  </CJField>
                </>
              );
            }
          })()}
        </CJForm>
      }
      footer={
        <Stack className="vsx-action-modal-footer">
          {
            <Row gap="medium" justify="end">
              <Button
                disabled={isSaved}
                onClick={props.onClose}
                type="tertiary"
                uppercase={true}
              >
                {'Cancel'}
              </Button>
              <Button
                disabled={!config.formState.isDirty || isSaved}
                onClick={() => {
                  setIsSaved(true);
                  config.submitForm();
                }}
                type={'primary'}
                uppercase={true}
              >
                {type === 'edit'
                  ? intl.formatMessage(content.editTagModalSaveButton)
                  : intl.formatMessage(content.createTagModalCreateButton)}
              </Button>
            </Row>
          }
        </Stack>
      }
    />
  );
};

export async function createTag(
  setTags: React.Dispatch<MaybeTrackedValue<UniversalTag>[]>,
  api: Api,
  cid: string,
  tag: CreateUniversalTag
) {
  const response = await api.createTag(cid, tag);
  setTags(response.tags);
}

export async function editTag(
  setTags: React.Dispatch<MaybeTrackedValue<UniversalTag>[]>,
  api: Api,
  cid: string,
  tag: Required<UniversalTag>
) {
  if (isUniversalTag(tag)) {
    const response = await api.editTag(cid, tag);
    setTags(response.tags);
  } else {
    throw new Error(`TagParam ${tag} was missing 'id'`);
  }
}

export const content = defineMessages({
  createTagModalHeader: {
    id: 'atossa.createmodal.header',
    defaultMessage: 'Create Tag',
  },
  tagModalNameLabel: {
    id: 'atossa.tagmodal.name.label',
    defaultMessage: 'Name',
  },
  tagModalUrlLabel: {
    id: 'atossa.tagmodal.url.label',
    defaultMessage: 'Website URL',
  },
  createTagModalCreateButton: {
    id: 'atossa.createmodal.create.button',
    defaultMessage: 'Create',
  },
  createTagModalCreatingButton: {
    id: 'atossa.createmodal.creating.button',
    defaultMessage: 'Creating...',
  },
  tagCreatedSuccessAlert: {
    id: 'atossa.tagcreated.success.alert',
    defaultMessage: 'Tag created.',
  },
  tagCreatedFailAlert: {
    id: 'atossa.tagcreated.fail.alert',
    defaultMessage: 'Tag creation failed.',
  },
  editTagModalHeader: {
    id: 'atossa.editmodal.header',
    defaultMessage: 'Edit Tag',
  },
  editTagModalSaveButton: {
    id: 'atossa.editmodal.save.button',
    defaultMessage: 'Save',
  },
  editTagModalSavingButton: {
    id: 'atossa.editmodal.saving.button',
    defaultMessage: 'Saving...',
  },
  tagSavedSuccessAlert: {
    id: 'atossa.tagsaved.success.alert',
    defaultMessage: 'Changes saved.',
  },
  tagSavedFailAlert: {
    id: 'atossa.tagsaved.fail.alert',
    defaultMessage: 'Changes failed.',
  },
  tagModalConsentLabel: {
    id: 'atossa.tagmodal.consent.label',
    defaultMessage: 'Consent Signal',
  },
  tagModalLoyaltyLabel: {
    id: 'atossa.tagmodal.loyalty.label',
    defaultMessage: 'Loyalty Exemption',
  },
  tagModalDisableWarning: {
    id: 'atossa.tagmodal.disable.warning',
    defaultMessage: 'Warning: disabling may impact revenue',
  },
});
