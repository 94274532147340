import { UniversalTag } from './index';
import isObject from '../isObject';

export type UpdateUniversalTag = Pick<
  UniversalTag,
  'name' | 'url' | 'consentConfig'
>;

export const isUpdateUniversalTag = (
  object: unknown
): object is UpdateUniversalTag => {
  const updateTag = object as UpdateUniversalTag;
  return (
    isObject(updateTag) &&
    (updateTag.name !== undefined ||
      updateTag.url !== undefined ||
      updateTag.consentConfig !== undefined)
  );
};
