import React, { useState } from 'react';
import * as R from 'ramda';
import {
  Partner,
  Partnership,
  PartnershipMode,
  TrackedValue,
} from '@atossa/core';
import { useNavigate, User } from '@platform/cj-platform-navigation';
import {
  CJField,
  CJForm,
  CJFormBlock,
  FieldValues,
  MultiSwitchInput,
  SelectInput,
  TextInput,
  useCJForm,
} from '@cjdev-internal/visual-stack-x/CJForm';
import { Button } from '@cjdev-internal/visual-stack-x/Button';
import { Row } from '@cjdev-internal/visual-stack-x/components/Row';
import { Api } from 'Api';
import { NavigateFunction } from 'react-router-dom';
import intl from 'utils/intl';
import { content as tagContent } from 'Components/AdvertiserTagView';
import { defineMessages } from 'react-intl';
import { ToastOptions, useToast } from '@cjdev-internal/visual-stack-x/Toast';

export interface PartnershipPageProps {
  api: Api;
  doc: Document;
  user: User;
  existingPartnerships: {
    tagId: string;
    partnerships?: TrackedValue<Partnership>[];
  };
}

interface FormPartnership {
  name?: Partner;
  key?: string;
  mode?: PartnershipMode;
}

const partnerField = 'partnerField';
const keyField = 'keyField';
const statusField = 'statusField';
const PartnerFieldRow = (props: {
  id: string;
  existingRows: Partnership[];
  partnerRows: { key: string; partnership?: FormPartnership }[];
  setPartnerRows: React.Dispatch<
    React.SetStateAction<
      {
        key: string;
        partnership?: FormPartnership;
      }[]
    >
  >;
  config: ReturnType<typeof useCJForm>;
}) => {
  if (props.config.getValues()[`${statusField}${props.id}`] === undefined) {
    props.config.setValue(`${statusField}${props.id}`, PartnershipMode.OFF);
  }
  return (
    <>
      <CJField
        columnSpan={1}
        required
        name={`${partnerField}${props.id}`}
        label={
          props.id === '0'
            ? intl.formatMessage(tagContent.partnerTableHeaderName)
            : undefined
        }
        help={
          props.config.watch(`${partnerField}${props.id}`) !==
          props.existingRows[parseInt(props.id)]?.name
            ? '* Changed'
            : ''
        }
      >
        <SelectInput
          required
          readOnly={parseInt(props.id) < props.existingRows.length}
          unpack
          name={`${partnerField}${props.id}`}
          placeholder="Partner"
          options={Object.values(Partner)
            .map((value) => ({ value, label: value }))
            .filter(
              (partner) =>
                !props.partnerRows
                  .map((row) => row.partnership?.name)
                  .includes(partner.value) ||
                props.partnerRows[parseInt(props.id)].partnership?.name ===
                  partner.value
            )}
          onChange={(e) => {
            const newPartnerRows = props.partnerRows.map((row) => {
              if (row.key === props.id) {
                return {
                  key: props.id,
                  partnership: {
                    name: e.target.value,
                    key: row.partnership?.key,
                    mode: row.partnership?.mode,
                  } as FormPartnership,
                };
              } else {
                return row;
              }
            });
            props.setPartnerRows([...newPartnerRows]);
          }}
        />
      </CJField>
      <CJField
        columnSpan={3}
        required
        name={`${keyField}${props.id}`}
        label={
          props.id === '0'
            ? intl.formatMessage(tagContent.partnerTableHeaderKey)
            : undefined
        }
        help={
          props.config.watch(`${keyField}${props.id}`) !==
          props.existingRows[parseInt(props.id)]?.key
            ? '* Changed'
            : ''
        }
        disabled={(() => {
          if (
            props.config.watch(`${statusField}${props.id}`) !== 'OFF' &&
            parseInt(props.id) < props.existingRows.length
          ) {
            if (
              props.config.watch(`${keyField}${props.id}`) !==
              props.existingRows[parseInt(props.id)]?.key
            ) {
              props.config.setValue(
                `${keyField}${props.id}`,
                props.existingRows[parseInt(props.id)]?.key
              );
            }
            return true;
          }
          return false;
        })()}
      >
        <TextInput required name={`${keyField}${props.id}`} />
      </CJField>
      <CJField
        columnSpan={2}
        required
        name={`${statusField}${props.id}`}
        label={
          props.id === '0'
            ? intl.formatMessage(tagContent.partnerTableHeaderMode)
            : undefined
        }
        help={
          props.config.watch(`${statusField}${props.id}`) !==
          props.existingRows[parseInt(props.id)]?.mode
            ? '* Changed'
            : ''
        }
      >
        <MultiSwitchInput
          required
          name={`${statusField}${props.id}`}
          options={Object.values(PartnershipMode)
            .map((value) => {
              let color:
                | 'info'
                | 'warning'
                | 'success'
                | 'alert'
                | 'interactive' = 'info';
              switch (value) {
                case PartnershipMode.OFF:
                  color = 'warning';
                  break;
                case PartnershipMode.TEST:
                  color = 'alert';
                  break;
                default:
                  color = 'success';
              }

              return {
                id: `${value}`,
                content: value,
                color,
              };
            })
            .reverse()}
          optionWidth="70px"
        />
      </CJField>
    </>
  );
};

const managePartnerships = async (
  api: Api,
  existingPartnerships: Partnership[],
  tagId: string,
  cid: string,
  data: FieldValues,
  rowCount: number,
  navigate: NavigateFunction,
  showToast: (options: ToastOptions) => string
) => {
  const partnerships = [...Array(rowCount).keys()].map((row: number) => {
    const partnership: Partnership = {
      name: data[partnerField + row.toString()],
      key: data[keyField + row.toString()],
      mode: data[statusField + row.toString()],
    };
    return partnership;
  });
  let savedPartners: String[] = [];
  const leavePage = (async () => {
    let leavePage = true;
    await Promise.all(
      partnerships.map(async (partnership, index) => {
        try {
          if (index < existingPartnerships.length) {
            if (!R.equals(partnership, existingPartnerships[index])) {
              await api.editPartnership(cid, { id: tagId }, partnership);
              savedPartners.push(
                intl.formatMessage(content.partnershipSaveSuccessAlert) +
                  ' ' +
                  partnership.name +
                  '.'
              );
            }
          } else {
            await api.createPartnership(cid, { id: tagId }, partnership);
            savedPartners.push(
              intl.formatMessage(content.partnershipSaveSuccessAlert) +
                ' ' +
                partnership.name +
                '.'
            );
          }
        } catch (e) {
          const failedMessage =
            intl.formatMessage(content.partnershipSaveFailAlert) +
            ' ' +
            partnership.name +
            '. ' +
            (e as Error).message;
          showToast({ message: failedMessage, type: 'warning' });
          leavePage = false;
        }
      })
    );
    return leavePage;
  })();
  if (await leavePage) {
    navigate('/list', { state: savedPartners });
  } else {
    savedPartners.forEach((partnerMessage) => {
      showToast({ message: partnerMessage, type: 'success' });
    });
  }
};

export const PartnershipPage = (props: PartnershipPageProps) => {
  const [toastMount, showToast] = useToast(true);
  const { api, user } = props;
  const { tagId } = props.existingPartnerships;
  const partnerships = props.existingPartnerships.partnerships || [];
  const cid = user.currentCompany.id.toString();
  const defaultValuesArray = partnerships?.map((partnership, index) => {
    const data: {
      [x: string]: string;
    } = {
      [partnerField + index.toString()]: partnership.value.name,
      [keyField + index.toString()]: partnership.value.key,
      [statusField + index.toString()]: partnership.value.mode,
    };
    return data;
  });
  const defaultValues = Object.assign({}, ...(defaultValuesArray || []));
  const config = useCJForm({
    defaultValues,
  });

  const [partnerRows, setPartnerRows] = useState<
    { key: string; partnership?: FormPartnership }[]
  >(
    [...Array(partnerships.length).keys()].map((index) => ({
      key: index.toString(),
      partnership: partnerships[index].value,
    }))
  );
  const [partnerRowCount, setPartnerRowCount] = useState<number>(
    partnerships ? partnerships.length : 0
  );
  const navigate = useNavigate();

  return (
    <>
      {toastMount}
      <CJForm
        {...config}
        heading={`Tag ID - ${tagId}`}
        showRequiredIndicator={true}
        formPageLayoutMode
        columnCount={6}
        id="PartnershipForm"
        onSubmit={async (data) => {
          try {
            await managePartnerships(
              api,
              partnerships.map((partnership) => partnership.value),
              tagId,
              cid,
              data,
              partnerRowCount,
              navigate,
              showToast
            );
          } catch (e) {
            const failedMessage = intl.formatMessage(
              content.partnershipSaveFailAlert
            );
            showToast({ message: failedMessage, type: 'warning' });
          }
        }}
      >
        <CJFormBlock>
          {partnerRowCount === 0 ? (
            <CJField columnSpan={'full'}>
              {intl.formatMessage(content.noPartners)}
            </CJField>
          ) : (
            <></>
          )}
          {partnerRows.map((row) => {
            return (
              <PartnerFieldRow
                id={row.key}
                existingRows={partnerships.map(
                  (partnership) => partnership.value
                )}
                partnerRows={partnerRows}
                setPartnerRows={setPartnerRows}
                config={config}
              />
            );
          })}
          <CJField align="start">
            <Button
              type="link"
              onClick={() => {
                setPartnerRowCount(partnerRowCount + 1);
                setPartnerRows([
                  ...partnerRows,
                  { key: partnerRowCount.toString(), partnership: undefined },
                ]);
              }}
            >
              {'+ Add Partner'}
            </Button>
          </CJField>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <Row align="end">
            <Button
              htmlType="button"
              type="tertiary"
              onClick={() => {
                navigate('/list');
              }}
            >
              CANCEL
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={!config.formState.isDirty}
            >
              SAVE
            </Button>
          </Row>
        </CJFormBlock>
      </CJForm>
    </>
  );
};

export const content = defineMessages({
  noPartners: {
    id: 'atossa.partnershipPage.noPartners',
    defaultMessage: 'No Partner Added',
  },
  partnershipSaveFailAlert: {
    id: 'atossa.partnershipPage.saveFail',
    defaultMessage: 'Failed to save',
  },
  partnershipSaveSuccessAlert: {
    id: 'atossa.partnershipPage.saveSuccess',
    defaultMessage: 'Successfully saved',
  },
});
